import React from "react"
import styled from "styled-components"
import { Field } from "formik"

// Styles
import colors from "../../../styles/colors"
import { mq } from "../../../styles/utils/mq"

interface IProps {
  name: string
  label: string
  error: string | undefined | boolean
  className?: string
}

const CheckboxField = ({ name, label, error, className }: IProps) => (
  <FieldSet className={className}>
    <FieldWrapper>
      <StyledCheckbox name={name} id={'form-' + name} type={`checkbox`} error={error ? 1 : 0} />
      <Label htmlFor={'form-' + name}>{label}</Label>
    </FieldWrapper>
    {error && <Error>{error}</Error>}
  </FieldSet>
)
const FieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: none;
  margin-bottom: 0;
`

const Label = styled.label`
  margin-bottom: 8px;
  color: ${colors.black};

  ${mq.from.XL`
    margin-bottom: 4px;
  `}
`

const FieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border: none;
  margin-bottom: 0;
`

const StyledCheckbox = styled(Field)`
  width: 40px;
  height: 40px;
`

const Error = styled.span`
  color: ${colors.accent1};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;

  ${mq.from.XL`
    font-size: 18px;
    line-height: 27px;
  `}
`

export default CheckboxField
