import React from "react"

// Styles
import colors from "../../styles/colors"

interface IProps {
  width?: number
  height?: number
  className?: string
}

const WarningSVG = ({ width = 36, height = 30, className }: IProps) => {
  return (
    <svg
      className={className}
      width={`${String(width)}px`}
      height={`${String(height)}px`}
      viewBox="0 0 36 30"
      fill="none"
      style={{
        width: `${String(width)}px`,
        height: `${String(height)}px`,
      }}
    >
      <path d="M18 0l17.32 30H.68L18 0z" fill={colors.accent1} />
      <path
        d="M17.187 20.613h1.575l.788-5.85v-3.17H16.4v3.17l.787 5.85zM16.381 25h3.187v-3.15h-3.187V25z"
        fill={colors.black}
      />
    </svg>
  )
}

export default WarningSVG
