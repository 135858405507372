import React from "react"
import styled from "styled-components"

// Component
import WhitepaperForm from "./forms/WhitepaperForm"
import { Col, Row } from "./layout/Grid"

// Types
import { IWhitepaperForm } from "../types/prismic/Whitepaper/WhitepaperForm"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import { mq } from "../styles/utils/mq"

const WhitepaperFormComponent = ({
  title,
  buttonLabel,
  setSubmitted,
  submitted,
  downloadUrl,
  whitepaperName
}: IWhitepaperForm) => {
  return (
    <Container>
      <StyledRow>
        <Col>
          <AnimatedScrollWrapper>
            {!submitted && (
              <WhitepaperForm
                title={title}
                buttonLabel={buttonLabel}
                setSuccess={setSubmitted}
                downloadUrl={downloadUrl}
                whitepaperName={whitepaperName}
              />
            )}
          </AnimatedScrollWrapper>
        </Col>
      </StyledRow>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  margin-top: 5%;
`

const StyledRow = styled(Row)`
  align-items: center;
  padding: 0 56px;

  ${mq.from.M`
    padding: 0 48px;
  `}

  ${mq.from.L`
    padding: 0;
  `}
`

export default WhitepaperFormComponent
