import React from "react"
import styled from "styled-components"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"

// Components
import { Row, Col } from "../components/layout/Grid"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"
import Image from "gatsby-background-image"
import { FluidObject } from "gatsby-image"
import { IWhitepaperHero } from "../types/prismic/Whitepaper"
import Link from "./generic/Link"
import { fontWeights } from "../styles/fonts"
// Types
export interface IProps {
  text: {
    default: string
    submitted: string
  }
  image: {
    url: FluidObject
    alt: string
  }
}

const WhitepaperHeroComponent = ({
  text,
  image,
  submitted,
  downloadButtonLabel,
  downloadUrl
}: IWhitepaperHero) => {
  return (
    <Container>
      <StyledRow>
        <Col m={6}>
          <AnimatedScrollWrapper>
            <StyledBackground>
              {image && <BackgroundImage fluid={image.url} alt={image.alt} />}
            </StyledBackground>
          </AnimatedScrollWrapper>
        </Col>
        <Col mOffset={1} m={8}>
          {!submitted && (
            <AnimatedScrollWrapper>
              <StyledText dangerouslySetInnerHTML={{ __html: text.default }} />
            </AnimatedScrollWrapper>
          )}

          {submitted && (
            <AnimatedScrollWrapper>
              <SubmitText
                dangerouslySetInnerHTML={{ __html: text.submitted }}
              />
              <SubmitWrapper>
                <Link
                  type="external"
                  color={colors.black}
                  title={downloadButtonLabel}
                  url={downloadUrl || ""}
                />
              </SubmitWrapper>
            </AnimatedScrollWrapper>
          )}
        </Col>
      </StyledRow>
    </Container>
  )
}

const StyledText = styled.p`
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  color: ${colors.black} ${mq.from.L`
    font-size: 24px;
  `};
`

const SubmitText = styled.p`
  font-size: 16px;
  font-weight: ${fontWeights.bold};
  color: ${colors.black}
  width: 80%
  ${mq.from.L`
    width: 100%;
    padding: 0;
    font-size: 24px;
  `}
`

const SubmitWrapper = styled.div`
  width: 100%;
  margin: 5% 0;
  ${mq.from.M`
    width: 70%;
  `}
  ${mq.from.L`
    width: 65%;
  `}
`
const StyledBackground = styled.div`
  padding: 1.5% 6%;
  background: #f6f6f6;
  margin-bottom: 24px;

  ${mq.from.M`
    padding: 5% 12.5%;
  `}
  ${mq.from.L`
    padding: 8% 18%;
  `}
`
const Container = styled.div`
  color: ${colors.black};
  width: 100%;
  overflow: hidden;
  margin-top: 5vh;
`
const BackgroundImage = styled(Image)`
  width: 100%;
  padding-top: 155%;

  ${mq.from.M`
    margin-bottom: 0;
  `}
`
const StyledRow = styled(Row)`
  align-items: center;
  padding: 0 32px;

  ${mq.from.M`
    padding: 0 48px;
  `}

  ${mq.from.L`
    padding: 0;
  `}
`
export default WhitepaperHeroComponent
