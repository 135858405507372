import React from "react"
import { graphql } from "gatsby"

// Types
import { ISliceType } from "../../types/prismic/Whitepaper/"

// Components
import WhitepaperPage from "./whitepaperTemplateView"

// Utils
const formatWhitepaperPagePrismicData = (slice: any, file: string, title: string) => {
  switch (slice.type as ISliceType) {
    case "whitepaper_hero":
      return {
        id: slice.id,
        type: slice.type,
        data: {
          text: {
            default: slice.primary.whitepaper_hero_default_text.html,
            submitted: slice.primary.whitepaper_hero_submitted_text.html,
          },
          image: {
            url: slice.primary.image.localFile.childImageSharp.fluid,
            alt: slice.primary.image.alt,
          },
          submitted: false,
          downloadUrl: file,
          whitepaperName: title,
          downloadButtonLabel: slice.primary.whitepaper_hero_submitted_button,
        },
      }
    case "whitepaper_form":
      return {
        id: slice.id,
        type: slice.type,
        data: {
          title: slice.primary.form_title,
          buttonLabel: slice.primary.submit_button_label,
          downloadUrl: file,
          whitepaperName: title,
          submitted: false,
        },
      }
    default:
      return null
  }
}

const WhitepaperPageContainer = ({ data }: any) => {
  if (!data.whitepaperPage) {
    return null
  }

  const whitepaperRawData = data.whitepaperPage.data

  const formattedData = {
    metaTitle: whitepaperRawData.metaTitle || "Whitepaper",
    metaDescription: whitepaperRawData.metaDescription || "Whitepaper desc",
  }

  const slices = whitepaperRawData.slices
    .filter((slice: any) => slice.type && slice)
    .map((slice: any) => formatWhitepaperPagePrismicData(slice, whitepaperRawData.file.url, whitepaperRawData.document_title))

  return <WhitepaperPage data={formattedData} slices={slices} />
}

export const query = graphql`
  query GetWhitepaperPage($slug: String!) {
    whitepaperPage: prismicWhitepaperPage(uid: { eq: $slug }) {
      data {
        document_title
        file {
          url
        }
        slices: body {
          ... on PrismicWhitepaperPageBodyWhitepaperHero {
            type: slice_type
            id
            primary {
              whitepaper_hero_default_text {
                html
              }
              whitepaper_hero_submitted_text {
                html
              }
              whitepaper_hero_submitted_button
              image: whitepaper_hero_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
              }
            }
          }
          ... on PrismicWhitepaperPageBodyWhitepaperForm {
            id
            type: slice_type
            primary {
              form_title
              submit_button_label
            }
          }
        }
      }
    }
  }
`

export default WhitepaperPageContainer
