import React from "react"
import styled from "styled-components"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"

// Styling
import { mq } from "../../styles/utils/mq"
import colors from "../../styles/colors"

// Components
import TextField from "./elements/TextField"
import Link from "../generic/Link"
import LoadingAnimaton from "../LoadingAnimation"
import { Col } from "../layout/Grid"
import CheckboxField from "./elements/CheckboxField"

interface IProps {
  title: string
  buttonLabel: string
  downloadUrl: string
  whitepaperName: string
  setSuccess: (arg: boolean) => void
}

interface IFormValues {
  name: string
  company: string
  email: string
  checkbox: boolean
}

const WhitepaperForm = ({ title, buttonLabel, downloadUrl, whitepaperName, setSuccess }: IProps) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    company: Yup.string().required("Please enter your company name"),
    checkbox: Yup.boolean().oneOf([true], "Please click the checkbox"),
    email: Yup.string()
      .email("This is not a valid email address")
      .required("Please enter your email address"),
  })

  const initialValues: IFormValues = {
    name: "",
    company: "",
    email: "",
    checkbox: false,
  }

  const handleSubmit = async (values: IFormValues) => {
    axios
      .post("/.netlify/functions/whitepaper", {
        ...values,
        downloadUrl,
        whitepaperName
      })
      .then(() => {
        window.setTimeout(() => window.scrollTo(0, 0), 0)
        setSuccess(true)
      })
      .catch(() => {
        window.setTimeout(() => window.scrollTo(0, 0), 0)
        setSuccess(true)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <>
          {isSubmitting ? (
            <AnimationWrapper lOffset={4} l={8}>
              <LoadingAnimaton show={isSubmitting} />
            </AnimationWrapper>
          ) : (
            <>
              <Title>{title}</Title>
              <StyledForm>
                <FieldsWrapper>
                  <StyledTextField
                    name="name"
                    label="Full name"
                    error={touched.name && errors.name}
                  />
                  <StyledTextField
                    name="company"
                    label="Company"
                    error={touched.company && errors.company}
                  />
                  <StyledTextField
                    name="email"
                    label="Email address"
                    type="email"
                    error={touched.email && errors.email}
                  />
                  <StyledCheckboxField
                    name="checkbox"
                    label="By downloading this whitepaper, I give permission to send me emails about Jungle Minds news and events."
                    error={touched.checkbox && errors.checkbox}
                  />
                </FieldsWrapper>
                <SubmitWrapper>
                  <Link
                    type="submit"
                    color={colors.black}
                    title={buttonLabel}
                    disabled={isSubmitting}
                  />
                </SubmitWrapper>
              </StyledForm>
            </>
          )}
        </>
      )}
    </Formik>
  )
}

const AnimationWrapper = styled(Col)`
  padding-top: 80px;
  padding-bottom: 80px;

  ${mq.from.L`
    padding-top: 120px;
    padding-bottom: 120px;
  `}

  ${mq.from.XL`
    padding-top: 160px;
    padding-bottom: 160px;
  `}
`

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 12px;

  ${mq.from.L`
    font-size: 40px;
    margin-bottom: 18px;
  `}

  ${mq.from.XL`
    margin-bottom: 24px;
  `}
`
const StyledForm = styled(Form)`
  margin-top: 48px;
  width: 100%;

  ${mq.from.M`
    margin-top: 64px;
  `}

  ${mq.from.XL`
    margin-top: 80px;
  `}
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.from.M`
    flex-direction: row;
    flex-wrap: wrap;
  `}
`
const SubmitWrapper = styled.div`
  width: 100%;
  ${mq.from.M`
    width: 40%;
    margin: 0 60%;
  `}
  ${mq.from.L`
    width: 18%;
    margin: 0 54%;
  `}
`

const StyledCheckboxField = styled(CheckboxField)`
  margin-top: 35px;
  margin-bottom: 6px;
  ${mq.from.M`
      /* duplicate style because of IE */
      width: calc(50% - 3.125vw);
      width: calc(50% - (var(--base-column-size) / 2));

      :nth-child(odd) {
        /* duplicate style because of IE */
        margin-right: 6vw;
        margin-right: var(--base-column-size);
      }
    `}
`

const StyledTextField = styled(TextField)`
  margin-bottom: 32px;

  label {
    color: ${colors.black};
  }
  input {
    color: ${colors.black};
    border: 1px solid ${colors.black};

    :focus {
      border: 1px solid ${colors.black};
      color: ${colors.black};
      outline: none;
    }
  }

  ${mq.from.M`
    /* duplicate style because of IE */
    width: calc(50% - 3.125vw);
    width: calc(50% - (var(--base-column-size) / 2));

    :nth-child(odd) {
      /* duplicate style because of IE */
      margin-right: 6vw;
      margin-right: var(--base-column-size);
    }
  `}
`

export default WhitepaperForm
