import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
// Types
import { ISlice } from "../../types/prismic/Whitepaper/"
// Styling
import colors from "../../styles/colors"
import { mq } from "../../styles/utils/mq"

// Utils
import PageContext from "../../utils/context/PageContext"

// Components
import SEO from "../../components/layout/SEO"
import WhitepaperHeroComponent from "../../components/WhitepaperHeroComponent"
import WhitepaperPageFormComponent from "../../components/WhitepaperFormComponent"

// Config
import { config as defaultPageTransitionsConfig } from "../../components/pageTransitions/config"

interface IData {
  metaTitle?: string
  metaDescription?: string
}

interface IProps {
  data: IData
  slices: ISlice[]
}

// Setup
const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.accent1,
  },
}

// Utils
const renderSlice = (
  slice: ISlice,
  submitted: { setSubmitted: any; submitted: boolean }
) => {
  switch (slice.type) {
    case "whitepaper_hero":
      slice.data.submitted = submitted.submitted
      return <WhitepaperHeroComponent {...slice.data} />
    case "whitepaper_form":
      slice.data.setSubmitted = submitted.setSubmitted
      slice.data.submitted = submitted.submitted
      return (
        <FormWrapper>
          <WhitepaperPageFormComponent {...slice.data} />
        </FormWrapper>
      )
    default:
      return null
  }
}

const WhitepaperPage = React.memo(({ data, slices }: IProps) => {
  const page = useContext(PageContext)
  const [submitted, setSubmitted] = useState<boolean>(false)

  useEffect(() => {
    page.updateColor(colors.white)
    page.updateTransition(pageTransitionsConfig)
  }, [])
  return (
    <>
      <Wrapper>
        <SEO title={data.metaTitle} description={data.metaDescription} />

        {slices &&
          slices.length > 0 &&
          slices.map((slice: ISlice) => (
            <div key={slice.id}>
              {renderSlice(slice, { setSubmitted, submitted })}
            </div>
          ))}
      </Wrapper>
    </>
  )
})

const FormWrapper = styled.section`
  /* duplicate style because of IE */
  margin-bottom: 12.5vw;
  margin-bottom: calc(var(--base-column-size) * 2);
`
const Wrapper = styled.section`
  padding: 0 1.5%;

  ${mq.from.M`
    padding: 0 2%;
  `}
  ${mq.from.L`
    padding: 0 12%;
  `}
`

export default WhitepaperPage