import React from "react"
import styled from "styled-components"
import { Field } from "formik"

// Components
import Warning from "../../icons/warning"

// Styles
import colors from "../../../styles/colors"
import { mq } from "../../../styles/utils/mq"

interface IProps {
  name: string
  label: string
  error: string | undefined | boolean
  type?: "text" | "email"
  className?: string
}

const TextField = ({
  name,
  label,
  error,
  type = "text",
  className,
}: IProps) => (
  <FieldSet className={className}>
    <Label>{label}</Label>
    <FieldWrapper>
      <StyledField name={name} type={type} error={error ? 1 : 0} />
      {error && <StyledWarning width={24} height={20} />}
    </FieldWrapper>
    {error && <Error>{error}</Error>}
  </FieldSet>
)

const FieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: none;
  margin-bottom: 0;
`

const Label = styled.label`
  margin-bottom: 8px;
  color: ${colors.white};

  ${mq.from.XL`
    margin-bottom: 4px;
  `}
`

const FieldWrapper = styled.div`
  position: relative;
`

const StyledField = styled(Field)`
  width: 100%;
  padding: 8px 48px 8px 10px;
  color: ${colors.white};
  background-color: transparent;
  border-radius: 0;
  border: 1px solid ${colors.white};

  :focus {
    color: ${colors.accent2};
    border: 1px solid ${colors.accent2};
    outline: none;
  }

  ${({ error }: { error: boolean }) =>
    error && `border: 1px solid ${colors.accent1}`};
`

const StyledWarning = styled(Warning)`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`

const Error = styled.span`
  color: ${colors.accent1};
  font-size: 16px;
  line-height: 24px;

  ${mq.from.XL`
    font-size: 18px;
    line-height: 27px;
  `}
`

export default TextField
