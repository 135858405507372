import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Lottie from "react-lottie"

// Lottie animation
import { default as loadingJson } from "../../static/lottieAnimations/loading.json"

interface IProps {
  show: boolean
}

const LoadingAnimation = ({ show }: IProps) => {
  const [fadeIn, setFadeIn] = useState<boolean>(false)

  const options = {
    loop: true,
    autoplay: true,
    animationData: loadingJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true)
    }, 200)
  }, [show])

  return (
    <Container fadeIn={fadeIn}>
      <Lottie options={options} speed={0.5} />
    </Container>
  )
}

const Container = styled.div`
  opacity: ${({ fadeIn }: { fadeIn: boolean }) => (fadeIn ? "1" : "0")};
  transition: opacity 0.2s ease-in-out;
`

export default LoadingAnimation
